<template>
	<div class="section-box" v-show="aftersaleType != aftersaleTypeEnum.upgrade">
		<div class="section-content">
			<div class="sub-title">付款信息</div>
			<el-table
				border
				style="margin-bottom: 18px;"
				header-cell-class-name="normal-table-header"
				:cell-style="{fontSize: '13px', color:'#666'}"
				:header-cell-style="{paddingLeft:'15px', fontSize: '13px', color:'#000'}"
				:data="droptOutInfoTable"
				:span-method="objectSpanMethodtui"
			>
				<template v-for="(item) of droptOutInfoColumns" >
					售后类型
					<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'refundType'" :label="item.label" :key="item.prop" :width="item.width">
						<template v-slot:default="{ row: { refundType } }">
							<p>{{ refundTypeMap[refundType] }}</p>
						</template>
					</el-table-column>
					<!-- 付款方式 -->
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'payType'" :label="item.label" :key="item.prop" :width="item.width">
						<template v-slot:default="{ row: { payType } }">
							<!-- <p>{{ payType == "0" ? "学员余额" : payType == '1' ? "银行账户" : '--' }}</p> -->
              <p>{{ payType == "0" ? "学员余额" : payType == '1' ? "银行账户" : payType == '10' || payType == '18'?'第三方':'--' }}</p>
						</template>
					</el-table-column>
          <!-- 第三方 -->
          <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'thirdPlatform' && showThird" :label="item.label" :key="item.prop" :width="item.width">
						<template slot-scope="scope">
							<p>{{ scope.row.payType == "10" || scope.row.payType == '18' ? scope.row.thirdPlatform:'--' }}</p>
						</template>
					</el-table-column>
					<!-- 学员开户名 -->
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'bankAccountName' && showXuebank"
						:key="item.label"
						:label="item.label"
						:prop="item.prop"
            :width="item.width"
					/>
					<!-- 学员开户行 -->
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'bank' && showXuebank"
						:key="item.label"
						:label="item.label"
						:prop="item.prop"
						:width="item.width"
					/>
					<!-- 学员开户账号 -->
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'bankAccount' && showXuebank"
						:key="item.label"
						:label="item.label"
						:prop="item.prop"
						:width="item.width"
					/>
					<!-- 付款银行账户 -->
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'ncAccountName' && showXuebank"
						:key="item.label"
						:label="item.label"
						:prop="item.prop"
						:width="item.width"
					>
						<template v-slot:default="{ row }">
							{{row.ncAccountName | empty}}
						</template>
					</el-table-column>
					<!-- 学员姓名 -->
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'nickName' && showXueName"
						:key="item.label"
						:label="item.label"
						:prop="item.prop"
						:width="item.width"
					/>
					<!-- 退费 -->
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundProccess'" :label="item.label" :key="item.prop">
						<template v-slot:default="{ row: { refundProccess } }">
							<p>{{refundMap[refundProccess]}}</p>
							<!-- <p v-if="refundProccess == '0'">待处理</p>
							<p v-if="refundProccess == '1'">已处理</p>
							<p v-if="refundProccess == '2'">处理异常</p> -->
							<!-- <p v-if="refundProccess !== '0' && refundProccess !== '1'">处理异常</p> -->
						</template>
					</el-table-column>
					<!-- 处理时间 -->
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'handleTime'" :label="item.label" :key="item.prop" width="160px">
						<template v-slot:default="{ row: { handleTime } }">
							<p v-if="handleTime">{{ handleTime | formatDate2('YYYY-MM-DD HH:mm:ss') }}</p>
							<p v-else>- -</p>
						</template>
					</el-table-column>

					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'aftersaleNo'" :key="item.label" :label="item.label" :prop="item.prop" :fixed="item.fixed" width="210px" />
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'receivedMoney'" :key="item.label" :label="item.label" :prop="item.prop" :fixed="item.fixed" width="90px" >
						<template v-slot:default="{row}">
							{{row.receivedMoney | decimal2}}
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'refundMoney'" :key="item.label" :label="item.label" :prop="item.prop" width="130px">
						<template v-slot:default="{row}">
							{{row.refundMoney | decimal2}}
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'mobile'" :key="item.label" :label="item.label" :prop="item.prop" />
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'balanceAccount'"
						:key="item.label"
						:label="item.label"
						:prop="item.prop"
						:fixed="item.fixed"
						width="150px"
					>
            <template v-slot:default="{row}">
              {{ row.balanceAccount?row.balanceAccount: '--' }}
            </template>
          </el-table-column>
				</template>

				<el-table-column :show-overflow-tooltip="true" label="操作" fixed="right">
					<template v-slot:default="{ row }">
						<a type="primary" @click="showPayinfoModal(row)">付款明细</a>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="section-line"></div>
	</div>
</template>

<script>
import { aftersaleTypeEnum, refundMap, refundTypeMap } from '@/views/order/constants'
export default {
	props: {
		aftersaleType: [String, Number],
		droptOutInfoTable: Array,
		showXuebank: Boolean,
		showXueName: Boolean,
    showThird: Boolean,
	},
	data(){
		return {
			aftersaleTypeEnum,
			refundMap,
			refundTypeMap,
			droptOutInfoColumns: [
        { label: "售后单号", prop: "aftersaleNo",fixed:true },
        { label: "已收金额", prop: "receivedMoney", fixed:true },
        { label: "核定可退费金额", prop: "refundMoney" },
        { label: "付款类型", prop: "refundType", width: 100},
        { label: "付款方式", prop: "payType", width: 100 },
        { label: "第三方", prop: "thirdPlatform", width: 150 },
        { label: "学员手机", prop: "balanceAccount" },
        { label: "学员姓名", prop: "nickName", width: 120 },
        { label: "学员开户名", prop: "bankAccountName", width: 120 },
        { label: "学员开户行", prop: "bank", width: 120 },
        { label: "学员开户行账号", prop: "bankAccount", width: 120 },
        { label: "付款银行账户", prop: "ncAccountName", width: 120 },
        { label: "退费", prop: "refundProccess" },
        { label: "处理时间", prop: "handleTime" },
      ],
		}
	},
	methods: {
		objectSpanMethodtui({ row, column, rowIndex, columnIndex }) {
      // console.log('droptOutInfoColumns.length', this.droptOutInfoColumns.length);
      // console.log('columnIndex', columnIndex);
      let codeindex = columnIndex
      if (codeindex === this.droptOutInfoColumns.length) {
        if (rowIndex % this.droptOutInfoTable.length === 0) {
          return {
            rowspan: this.droptOutInfoTable.length,
            colspan: 1,
          }
        }
      } else if (codeindex === 0 || codeindex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
		showPayinfoModal(row){
			this.$emit('showPayinfoModal', row)
		}
	}
}
</script>

<style lang="scss" scoped>
.section-box{
	.section-content{
		width: 94%;
		margin: 0 auto;
		padding-top: 18px;
		padding-bottom: 18px;
	}
	.sub-title {
		font-size: 16px;
		font-weight: bold;
		color: #333333;
		padding-bottom: 13px;
	}
	.cell p{
		margin-bottom: 0;
	}
	.section-line{
		height: 12px;
		background: #F5F5FA;
	}
}
</style>